import { StaticJsonRpcProvider } from '@ethersproject/providers'
import getRpcUrl from 'utils/getRpcUrl'
import getRpcUrlTestnet from 'utils/getRpcUrlTestnet'

const RPC_URL = getRpcUrl()
const RPC_URL_TESTNET = getRpcUrlTestnet()

export const simpleRpcProvider = new StaticJsonRpcProvider(RPC_URL)

export const simpleTestnetRpcProvider = new StaticJsonRpcProvider(RPC_URL_TESTNET)

export default null
