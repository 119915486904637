import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = [
  process.env.NEXT_PUBLIC_NODE_TESTNET_1,
  process.env.NEXT_PUBLIC_NODE_TESTNET_2,
  process.env.NEXT_PUBLIC_NODE_TESTNET_3,
]

const getNodeUrlTestnet = () => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the NEXT_PUBLIC_NODE_PRODUCTION from env file
  return sample(nodes)
}

export default getNodeUrlTestnet
