import { ResetCSS, useModal } from '@pancakeswap/uikit'
import Script from 'next/script'
import BigNumber from 'bignumber.js'
import { NextQueryParamProvider } from 'next-query-params'
import EasterEgg from 'components/EasterEgg'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import SubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useInactiveListener } from 'hooks/useInactiveListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment, useEffect } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { useUserAgreedTermsAndConditions } from 'state/user/hooks'
import { TermsAndConditionModal } from 'components/TermsAndConditions/TermsAndConditionModal'
// eslint-disable-next-line import/no-unresolved
import { createWeb3Modal, defaultWagmiConfig, useWeb3Modal } from '@web3modal/wagmi/react'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'
import { WagmiConfig, configureChains, createConfig, useAccount, useConfig, useConnect } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { bsc } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import BlockCountry from '../components/BlockCountry'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import WalletModal, { WalletView } from 'components/Menu/UserMenu/WalletModal'

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useUserAgent()
  useInactiveListener()
  useSentryUser()
  return null
}

const projectId = '083305249ab43e78f20f52ad13fa95cb'

const metadata = {
  name: 'HODLX Exchange',
  description: 'HODLX Exchange',
  url: 'https://hodlx.exchange/',
  icons: ['https://hodlx.exchange/logo.png'],
}

const { chains, publicClient } = configureChains([bsc], [walletConnectProvider({ projectId }), publicProvider()])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } }),
  ],
  publicClient,
})

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  defaultChain: bsc,
  /*
  customWallets: [
    {
      id: 'HODL_App',
      name: 'HODL Wallet',
      homepage: 'hodltoken.net', // Optional
      image_url: '/images/0x0E9766dF73973abCfEDDE700497c57110ee5c301.png', // Optional
      mobile_link: 'HODL://', // Optional - Deeplink or universal
      // app_store: 'app_store', // Optional
      // play_store: 'play_store' // Optional
    },
  ],
  */
})

function MyApp(props: AppProps<{ initialReduxState: any }>) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content="Hodlx Finance" />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="https://hodlx.exchange/images/hero.png" />
        <meta name="twitter:description" content="Hodlx Finance" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="HODLX - The Friendly DEX" />
        <title>Hodlx Finance</title>
      </Head>
      <WagmiConfig config={wagmiConfig}>
        <Providers store={store}>
          <Blocklist>
            <GlobalHooks />
            <Updaters />
            <ResetCSS />
            <GlobalStyle />
            <GlobalCheckClaimStatus excludeLocations={[]} />
            <PersistGate loading={null} persistor={persistor}>
              <BlockCountry />
              <App {...props} />
            </PersistGate>
          </Blocklist>
        </Providers>
      </WagmiConfig>
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment

  const [userAgreedTermsAndConditions, setUserAgreedTermsAndConditions] = useUserAgreedTermsAndConditions()

  const { isConnected } = useAccount()
  const { connect } = useConnect()
  const { connectors } = useConfig()
  const { chainId } = useActiveWeb3React()
  const { open, close } = useWeb3Modal()

  useEffect(() => {
    localStorage.setItem('walletConnectState', isConnected.toString())
  }, [isConnected])

  useEffect(() => {
    const isHODLApp = !!window.ethereum?.isHODLApp
    const state = localStorage.getItem('walletConnectState') || ''

    const disconnect = async () => {
      await open({ view: 'Networks' })
    }

    if (chainId && chainId === 56 && !isConnected) {
      if (isHODLApp || state === 'true') {
        try {
          const injected = connectors.find((c) => c.id === 'injected')
          if (injected !== undefined) connect({ connector: injected })
        } catch (error) {
          console.log(error)
        }
      }
    } else if (chainId !== 56) {
      disconnect()
    } else if (chainId === 56) {
      close()
    }
  }, [userAgreedTermsAndConditions, isConnected, chainId])

  const [onStartup] = useModal(<TermsAndConditionModal />, true, true, 'termsAndConditionModal')

  useEffect(() => {
    const termsModalTimeout = setTimeout(() => {
      const state = localStorage.getItem('agreedTermsAndConditionsState') || ''
      if (!userAgreedTermsAndConditions && state !== 'true') {
        onStartup()
      }
    }, 1000)
    return () => clearTimeout(termsModalTimeout)
  }, [])

  return (
    <ProductionErrorBoundary>
      <NextQueryParamProvider>
        <Menu>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Menu>
      </NextQueryParamProvider>
      <EasterEgg iterations={2} />
      <ToastListener />
      <SubgraphHealthIndicator />
    </ProductionErrorBoundary>
  )
}

export default MyApp
