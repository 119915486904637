import { useEffect, useState, useRef } from 'react'
import { Address, Connector, useAccount, useNetwork } from 'wagmi'
import { JsonRpcProvider, Web3Provider, FallbackProvider } from '@ethersproject/providers'
import { simpleRpcProvider } from 'utils/providers'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { CHAIN_ID } from 'config/constants/networks'
import { useWeb3React } from '@web3-react/core'
import { useEthersProvider, useEthersSigner } from 'utils/ethers'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): {
  library: FallbackProvider | JsonRpcProvider
  chainId: number
  account: Address
  connector: Connector<any, any>
} => {
  // const { library, chainId, ...web3React } = useWeb3React()
  const library = useEthersProvider()
  const { address: account, connector } = useAccount()
  const { chain } = useNetwork()

  const chainId = chain ? chain.id : Number(CHAIN_ID)
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || simpleRpcProvider)

  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || simpleRpcProvider)
      refEth.current = library
    }
  }, [library])

  return { library: provider, chainId, account, connector }
}

export default useActiveWeb3React
