import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
// eslint-disable-next-line import/no-unresolved
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useConnect } from 'wagmi'
import truncateHash from 'utils/truncateHash'
import Trans from './Trans'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  const { open } = useWeb3Modal()
  const { connect } = useConnect()
  const { address, isConnecting, isDisconnected } = useAccount()
  const truncatedAddress = address ? truncateHash(address, 2) : 'Connecting...'

  return (
    <Button onClick={() => open({ view: 'Networks' })} {...props}>
      <Trans>{isDisconnected ? 'Connect Wallet' : `${truncatedAddress}`}</Trans>
    </Button>
  )
}

export default ConnectWalletButton
